<script lang="ts">
    import { getContext } from "svelte";
    import { on } from "svelte/events";
    import Cell from "./Cell.svelte";
    import type { Writable } from "svelte/store";
    import type { CellState } from "./types";

    interface Props {
        size: number;
        readonly: boolean;
        cloak?: boolean;
    }

    let { size, readonly, cloak = false }: Props = $props();

    let placements = getContext<Writable<Array<CellState>>>("placements");

    let pressing = $state(false);

    // This is to override the touch behavour for the grid (drag place markers)
    let grid: Element | undefined = $state();
    $effect(() => {
        if (grid) {
            return on(
                grid,
                "touchmove",
                (e) => {
                    if (!readonly) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                },
                { passive: false },
            );
        }
    });
</script>

<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
    bind:this={grid}
    class="puzzle-grid"
    style="--rows: {size}; --cols: {size}; filter: blur({cloak ? '1.5rem' : '0'});"
    oncontextmenu={(e) => e.preventDefault()}
    onpointerleave={() => (pressing = false)}
>
    {#each $placements as state, index}
        <Cell {state} {index} {readonly} bind:pressing />
    {/each}
</div>
