<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { CellState } from "./types";
    import ResetIcon from "./icons/Reset.svelte";

    const placements = getContext<Writable<Array<CellState>>>("placements");
    const snapshots = getContext<Writable<Array<Array<CellState>>>>("snapshots");

    const reset = () => {
        placements.update((p) => {
            // Snapshot the current state case this was a mistake.
            snapshots.update((s: Array<Array<CellState>>) => {
                s.push(p.slice());
                return s;
            });

            // Preserve hints, reset anything else.
            return p.map((cell) => {
                if (cell === CellState.StarHint) {
                    return CellState.StarHint;
                } else {
                    return CellState.Empty;
                }
            });
        });
    };
</script>

<button class="icon" onclick={reset}>
    <ResetIcon />
</button>
