<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { CellState, type Puzzle, type Timer } from "./types";
    import { hint, reveal } from "./api";
    import { validatePlacements } from "./util";

    const puzzle = getContext<Puzzle>("puzzle");
    const session = getContext<string>("session");
    const placements = getContext<Writable<Array<CellState>>>("placements");
    const timer = getContext<Writable<Timer>>("timer");

    let requireConfirmation = $state(false);
    let hintsRemaining = $state(
        3 - $placements.filter((cell) => cell === CellState.StarHint).length,
    );

    const nextHint = async () => {
        const kings = $placements.reduce<Array<number>>((acc, cell, i) => {
            if (
                cell === CellState.Star ||
                cell === CellState.StarInvalid ||
                cell === CellState.StarHint
            ) {
                acc.push(i);
            }
            return acc;
        }, []);

        try {
            const h = await hint(session, kings);
            if (h) {
                placements.update((p) => {
                    p[h.position] = CellState.StarHint;
                    p = validatePlacements(puzzle, p);
                    return p;
                });

                hintsRemaining -= 1;
            }
        } catch (e) {
            // noop
        }
    };

    const giveUp = () => {
        requireConfirmation = true;

        setTimeout(() => {
            requireConfirmation = false;
        }, 3000);
    };

    const confirmReveal = async () => {
        try {
            const solution = await reveal(session, $timer.elapsed);
            if (solution) {
                placements.update((p) => {
                    p = p.map((_, i) => {
                        if (solution.positions.includes(i)) {
                            return CellState.StarHint;
                        } else {
                            return CellState.Empty;
                        }
                    });
                    return p;
                });
            }
        } catch (e) {
            // noop
        }
    };
</script>

{#if hintsRemaining > 0}
    <button onclick={nextHint}>
        Hint ({hintsRemaining})
    </button>
{:else if requireConfirmation}
    <button class="confirm" onclick={confirmReveal}>Confirm</button>
{:else}
    <button onclick={giveUp}>Reveal</button>
{/if}

<style>
    button {
        height: 1.5rem;
        padding: 0px 1em;
        font-size: 0.8em;
        border: 1px solid var(--primary);
        border-radius: 100px;
        background-color: var(--bg);
        color: var(--primary);
        font-weight: 500;
        cursor: pointer;
        text-transform: uppercase;
    }

    button.confirm {
        background-color: var(--primary);
        color: var(--bg);
    }
</style>
