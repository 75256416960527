<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import type { Writable } from "svelte/store";
    import { Routes, CellState, type Attempt, type Puzzle } from "@lib/types";
    import { PuzzleDate } from "@lib/util";
    import { db } from "@lib/db";
    import { play } from "@lib/api";
    import PuzzleComponent from "@lib/Puzzle.svelte";
    import BackButton from "@lib/BackButton.svelte";
    import Settings from "@lib/Settings.svelte";
    import { fade } from "svelte/transition";

    let router = getContext<Writable<Routes>>("router");
    let date = getContext<Writable<PuzzleDate>>("date");

    let puzzle: Puzzle | undefined = $state(undefined);
    let attempt: Attempt | undefined = $state(undefined);

    onDestroy(
        date.subscribe(async (newDate: PuzzleDate) => {
            try {
                puzzle = await db.puzzles.get({ date: newDate.iso });
            } catch (e) {
                // noop
            }

            if (!puzzle) {
                try {
                    const { session, ...newPuzzle } = await play(newDate.iso);
                    if (newPuzzle) {
                        const newAttempt = {
                            puzzle_id: newPuzzle.id,
                            session: session,
                            timer: { elapsed: 0, paused: false },
                            placements: Array(newPuzzle.size ** 2).fill(
                                CellState.Empty,
                            ),
                            snapshots: [],
                            solved: false,
                        };

                        await db.transaction(
                            "rw",
                            db.puzzles,
                            db.attempts,
                            async () => {
                                await db.puzzles.put(newPuzzle);
                                await db.attempts.put(newAttempt);
                            },
                        );

                        puzzle = newPuzzle;
                        attempt = newAttempt;
                    }
                } catch (e) {
                    // noop
                    goToMenu();
                }
            }

            if (puzzle && !attempt) {
                try {
                    attempt = await db.attempts.get({ puzzle_id: puzzle.id });
                } catch (e) {
                    // noop
                    goToMenu();
                }
            }
        }),
    );

    const goToMenu = () => {
        router.set(Routes.Menu);
    };
</script>

<div class="container">
    <main>
        {#if puzzle && attempt}
            <div transition:fade={{ duration: 150 }}>
                <header class="puzzle-header">
                    <div>
                        <BackButton onclick={goToMenu} />
                    </div>
                    <div class="title">
                        <h3>TWO KINGS</h3>
                        <h5>#{puzzle.id}</h5>
                    </div>
                    <div class="options">
                        <Settings />
                    </div>
                </header>
                <PuzzleComponent {puzzle} {attempt} />
            </div>
        {/if}
    </main>
</div>
