<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import type { Writable } from "svelte/store";
    import { readable } from "svelte/store";
    import { Routes } from "@lib/types";
    import { PuzzleDate } from "@lib/util";

    let router = getContext<Writable<Routes>>("router");
    let date = getContext<Writable<PuzzleDate>>("date");

    const resetTimer = readable(0, (set) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        set(tomorrow.getTime() - new Date().getTime());

        const interval = setInterval(() => {
            const now = new Date();
            set(tomorrow.getTime() - now.getTime());
        }, 1000);

        return () => clearInterval(interval);
    });

    const unsubscribe = resetTimer.subscribe((t) => {
        if (t < 0) {
            unsubscribe();
            router.set(Routes.Menu);
            date.set(new PuzzleDate());
        }
    });

    onDestroy(unsubscribe);
</script>
