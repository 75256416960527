import { mount } from 'svelte'
import * as Sentry from "@sentry/svelte";
import './app.css'
import App from './App.svelte'

Sentry.init({
    dsn: "https://b6218d672306d5242125416bdfafe57e@o510821.ingest.us.sentry.io/4508186631733248",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],

    environment: import.meta.env.MODE,

    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost", /^https:\/\/twokings\.xyz/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const app = mount(App, { target: document.getElementById('app')! })

export default app
