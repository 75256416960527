import type { Hint, Puzzle, Solution, Stats } from "./types";

export type PlayResponse = Puzzle & {
    session: string;
};

export const play = async (date: string): Promise<PlayResponse> => {
    const res = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/play`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ date }),
        },
    );
    if (!res.ok) {
        throw new Error("Failed to start attempt");
    }

    return res.json();
};

export const solve = async (
    session: string,
    solution: string,
    time: number,
): Promise<Stats> => {
    const res = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/solve`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ session, solution, time }),
        },
    );
    if (!res.ok) {
        throw new Error("Failed to solve");
    }

    return res.json();
};

export const hint = async (
    session: string,
    kings: Array<number>,
): Promise<Hint> => {
    const res = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/hint`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ session, kings }),
        },
    );
    if (!res.ok) {
        throw new Error("Failed to fetch hint");
    }

    return res.json();
};

export const reveal = async (
    session: string,
    time: number,
): Promise<Solution> => {
    const res = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/reveal`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ session, time }),
        },
    );
    if (!res.ok) {
        throw new Error("Failed to fetch solution");
    }

    return res.json();
};
