export enum Routes {
    Menu = "menu",
    Puzzle = "puzzle",
    Archive = "archive"
};

export type Puzzle = {
    id: number;
    date: string;
    fit: number;
    size: number;
    grid: Array<number>;
};

export type Attempt = {
    id?: number;
    puzzle_id: number;
    session: string;
    timer: Timer;
    placements: Array<CellState>;
    snapshots: Array<Array<CellState>>;
    solved: boolean;
};

export enum CellState {
    Empty,
    X,
    Star,
    StarInvalid,
    StarHint,
}

export type Stats = {
    revealed: boolean;
    hints_used: number;
    solve_time: number;
    attempts_counter: number;
    solved_counter: number;
    avg_time: number;
};

export type Timer = {
    paused: boolean;
    elapsed: number;
};

export type Hint = {
    position: number;
};

export type Solution = {
    positions: Array<number>;
};
