<script lang="ts">
    import { getContext, onDestroy, onMount, setContext } from "svelte";
    import { writable, type Writable } from "svelte/store";
    import { CellState, Routes } from "./types";
    import type { Timer, Puzzle, Attempt } from "./types";
    import { db } from "./db";
    import { solve } from "./api";
    import { isSolved } from "./util";
    import Clock from "./Clock.svelte";
    import Grid from "./Grid.svelte";
    import HintButton from "./HintButton.svelte";
    import ResetButton from "./ResetButton.svelte";
    import StepControl from "./StepControl.svelte";
    import Tip from "./Tip.svelte";

    interface Props {
        puzzle: Puzzle;
        attempt: Attempt;
    }

    let { puzzle, attempt }: Props = $props();

    const router = getContext<Writable<Routes>>("router");

    const timer = writable<Timer>(attempt.timer);
    const placements = writable<Array<CellState>>(attempt.placements);
    const snapshots = writable<Array<Array<CellState>>>(attempt.snapshots);

    setContext("puzzle", puzzle);
    setContext("session", attempt.session);
    setContext("timer", timer);
    setContext("placements", placements);
    setContext("snapshots", snapshots);

    let failedToSolve = $state(false);

    const handleSolve = async () => {
        const solution = isSolved(puzzle, $placements);
        if (!solution) return;

        try {
            await solve(attempt.session, solution, $timer.elapsed);

            if (!attempt.solved) {
                await db.attempts.update(attempt.id, { solved: true });
                router.set(Routes.Menu);
            }
        } catch (e) {
            // Offer a retry button, pause timer etc.
            timer.update((t: Timer) => ({ ...t, paused: true }));
            failedToSolve = true;
        }
    };

    onDestroy(
        timer.subscribe(async (t) => {
            try {
                await db.attempts.update(attempt.id, {
                    timer: $state.snapshot(t),
                });
            } catch (e) {
                // noop
            }
        }),
    );

    onDestroy(
        placements.subscribe(async (p) => {
            try {
                await db.attempts.update(attempt.id, {
                    placements: $state.snapshot(p),
                });
            } catch (e) {
                // noop
            }

            handleSolve();
        }),
    );

    onDestroy(
        snapshots.subscribe(async (s) => {
            try {
                await db.attempts.update(attempt.id, {
                    snapshots: $state.snapshot(s),
                });
            } catch (e) {
                // noop
            }
        }),
    );

    const onVisibilityChange = () => {
        if (!attempt.solved && document.visibilityState === "hidden") {
            timer.update((t: Timer) => ({
                ...t,
                paused: true,
            }));
        }
    };

    onMount(() => {
        timer.update((t: Timer) => ({ ...t, paused: attempt.solved }));
    });

    onDestroy(() => {
        timer.update((t: Timer) => ({ ...t, paused: true }));
    });
</script>

<svelte:document onvisibilitychange={onVisibilityChange} />

{#if failedToSolve}
    <button class="link" type="button" onclick={handleSolve}>
        Unexpected error occured, click to retry
    </button>
{/if}

<section class="puzzle">
    <div class="controls">
        {#if !attempt.solved}
            <Clock />
        {/if}
        {#if !$timer.paused}
            <HintButton />
        {/if}
    </div>

    <Grid
        size={puzzle.size}
        readonly={$timer.paused}
        cloak={$timer.paused && !attempt.solved}
    />

    <div class="controls">
        {#if !$timer.paused}
            <ResetButton />
            <StepControl />
        {/if}
    </div>

    <Tip />

    {#if !attempt.solved}
        <div class="instructions">
            <p>
                <b>INSTRUCTIONS:</b> Press to place a marker. Press again to place
                a king.
            </p>

            <p>
                <b>OBJECTIVE:</b> Place 2 kings in each row, column, and region.
                Kings cannot be placed in adjacent cells.
            </p>
        </div>
    {/if}
</section>
