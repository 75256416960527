<script lang="ts">
    import { writable } from "svelte/store";
    import { setContext, type Component } from "svelte";
    import { Routes } from "@lib/types";
    import { PuzzleDate } from "@lib/util";
    import { settings } from "@lib/store";
    import Menu from "@pages/Menu.svelte";
    import Archive from "@pages/Archive.svelte";
    import Puzzle from "@pages/Puzzle.svelte";

    // Router
    let Page: Component = $state(Menu);

    let router = writable<Routes>(Routes.Menu);
    setContext("router", router);

    router.subscribe((current) => {
        switch (current) {
            case Routes.Menu:
                Page = Menu;
                break;
            case Routes.Puzzle:
                Page = Puzzle;
                break;
            case Routes.Archive:
                Page = Archive;
                break;
        }
    });

    // Puzzle Date
    let date = writable<PuzzleDate>(new PuzzleDate());
    setContext("date", date);

    // Dark mode
    settings.subscribe((s) => {
        if (s.dark_mode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    });
</script>

<Page />

