<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { getDatesInRange, PuzzleDate } from "@lib/util";
    import Logo from "@components/Logo.svelte";
    import ArchiveEntry from "@components/ArchiveEntry.svelte";
    import Back from "@icons/Back.svelte";
    import Forward from "@icons/Forward.svelte";
    import ResetTimer from "@components/ResetTimer.svelte";

    let date = getContext<Writable<PuzzleDate>>("date");

    let year = $state($date.date.getUTCFullYear());
    let month = $state($date.date.getUTCMonth());

    let FIRST_PUZZLE = new Date();
    FIRST_PUZZLE.setUTCHours(0, 0, 0, 0);
    FIRST_PUZZLE.setUTCFullYear(2024);
    FIRST_PUZZLE.setUTCMonth(6, 10);

    let dates = $derived.by(() => {
        let start = new Date();
        start.setUTCHours(0, 0, 0, 0);
        start.setUTCFullYear(year);
        start.setUTCMonth(month, 1);
        start.setUTCDate(start.getUTCDate() - (start.getUTCDay() - 1));

        let end = new Date();
        end.setUTCHours(0, 0, 0, 0);
        end.setUTCFullYear(year);
        end.setUTCMonth(month + 1, 0);
        end.setUTCDate(end.getUTCDate() + (6 - end.getUTCDay()) + 1);

        return getDatesInRange(start, end);
    });

    const previousMonth = () => {
        month = month - 1;
        if (month < 0) {
            month = 11;
            year = year - 1;
        }
    };

    const nextMonth = () => {
        month = month + 1;
        if (month > 11) {
            month = 0;
            year = year + 1;
        }
    };
</script>

<ResetTimer />

<div class="container">
    <main>
        <header>
            <Logo />
            <h4>TWO KINGS</h4>
        </header>

        <section>
            <h1 class="fade-in">Archive</h1>
            <div class="calendar-controls fade-in">
                <button class="icon" onclick={previousMonth}>
                    <Back />
                </button>
                <h2>
                    {new Date(year, month, 1).toLocaleDateString("en-us", {
                        month: "long",
                    })}
                    {year}
                </h2>
                <button class="icon" onclick={nextMonth}>
                    <Forward />
                </button>
            </div>
            <div class="calendar-days">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Wed</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
            </div>
            <div class="calendar">
                {#each dates as puzzleDate (puzzleDate.iso)}
                    <ArchiveEntry {puzzleDate} disabled={puzzleDate.date < FIRST_PUZZLE} />
                {/each}
            </div>
        </section>

        <footer></footer>
    </main>
</div>

<style>
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    h1 {
        text-align: center;
        margin-bottom: 1rem;
    }

    main {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .container {
        background-color: var(--accent-color);
        color: var(--black);
    }

    .calendar-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .calendar,
    .calendar-days {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 0.5rem;
    }
</style>
