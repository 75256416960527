import { CellState, type Puzzle } from "./types";

export class PuzzleDate {
    date: Date;
    iso: string;
    friendly: string;

    constructor(date: Date = new Date()) {
        this.date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        this.iso = `${this.date.toISOString().split("T")[0]}`;
        this.friendly = this.date.toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });
    }

    isFuture(): boolean {
        let now = new Date();
        now = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000);

        return now.getUTCFullYear() < this.date.getUTCFullYear() ||
            (now.getUTCFullYear() === this.date.getUTCFullYear() && now.getUTCMonth() < this.date.getUTCMonth()) ||
            (now.getUTCFullYear() === this.date.getUTCFullYear() && now.getUTCMonth() === this.date.getUTCMonth() && now.getUTCDate() < this.date.getUTCDate());
    }
}

export const getDatesInRange = (start: Date, end: Date): Array<PuzzleDate> => {
    const date = new Date(start.getTime());
    const dates = [];
    while (date <= end) {
        dates.push(new PuzzleDate(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

export const formatTime = (ms: number) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    if (hours > 0) {
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")
            }:${String(seconds).padStart(2, "0")}`;
    } else {
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")
            }`;
    }
};

export const isSolved = (puzzle: Puzzle, placements: number[]) => {
    let stars = 0;
    for (let i = 0; i < placements.length; i++) {
        if (
            placements[i] === CellState.Empty || placements[i] === CellState.X
        ) {
            continue;
        }

        if (placements[i] === CellState.StarInvalid) {
            return false;
        }

        stars++;
    }

    const solved = stars === puzzle.size * puzzle.fit;

    if (solved) {
        let soltuion = "";
        for (let r = 0; r < puzzle.size; r++) {
            for (let c = 0; c < puzzle.size; c++) {
                const idx = r * puzzle.size + c;
                if (
                    placements[idx] === CellState.Star ||
                    placements[idx] === CellState.StarHint
                ) {
                    soltuion += `${c + 1}`;
                    break; // Jump to next row
                }
            }
        }
        return soltuion;
    } else {
        return null;
    }
};

export const validatePlacements = (puzzle: Puzzle, placements: number[]) => {
    for (let i = 0; i < placements.length; i++) {
        if (
            placements[i] === CellState.Empty || placements[i] === CellState.X
        ) {
            continue;
        }

        if (checkRow(puzzle, placements, i)) {
            // Skip to next row
            i = Math.floor(i / puzzle.size) * puzzle.size + puzzle.size - 1;
            continue;
        } else if (checkCol(puzzle, placements, i)) {
            // Continue to next cell in row
            continue;
        } else if (checkSurrounding(puzzle, placements, i)) {
            continue;
        } else if (checkRegion(puzzle, placements, i)) {
            // Nothing to do, last check... response doesn't matter.
        }
    }

    return placements;
};

const checkRow = (
    puzzle: Puzzle,
    placements: number[],
    index: number,
): boolean => {
    const row = Math.floor(index / puzzle.size);
    const start = row * puzzle.size;
    const end = start + puzzle.size;

    const stars = [];
    for (let i = start; i < end; i++) {
        if (
            placements[i] === CellState.Star ||
            placements[i] === CellState.StarInvalid ||
            placements[i] === CellState.StarHint
        ) {
            stars.push(i);
        }
    }

    if (stars.length > puzzle.fit) {
        for (let j = 0; j < stars.length; j++) {
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.StarInvalid;
        }
        return true;
    } else {
        for (let j = 0; j < stars.length; j++) {
            // We don't want to overwrite a star that was already confirmed invalid.
            if (
                stars[j] < index &&
                placements[stars[j]] === CellState.StarInvalid
            ) {
                continue;
            }
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.Star;
        }
        return false;
    }
};

const checkCol = (
    puzzle: Puzzle,
    placements: number[],
    index: number,
): boolean => {
    const col = index % puzzle.size;
    const start = col;
    const end = puzzle.size * puzzle.size + col;

    const stars = [];
    for (let i = start; i < end; i += puzzle.size) {
        if (
            placements[i] === CellState.Star ||
            placements[i] === CellState.StarInvalid ||
            placements[i] === CellState.StarHint
        ) {
            stars.push(i);
        }
    }

    if (stars.length > puzzle.fit) {
        for (let j = 0; j < stars.length; j++) {
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.StarInvalid;
        }
        return true;
    } else {
        for (let j = 0; j < stars.length; j++) {
            // We don't want to overwrite a star that was already confirmed invalid.
            if (
                stars[j] < index &&
                placements[stars[j]] === CellState.StarInvalid
            ) {
                continue;
            }
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.Star;
        }
        return false;
    }
};

const checkSurrounding = (
    puzzle: Puzzle,
    placements: number[],
    index: number,
): boolean => {
    const firstRow = index < puzzle.size;
    const lastRow = index >= puzzle.size * (puzzle.size - 1);
    const firstCol = index % puzzle.size === 0;
    const lastCol = index % puzzle.size === puzzle.size - 1;

    const surrounding = [index];
    if (!firstRow) surrounding.push(index - puzzle.size);
    if (!lastRow) surrounding.push(index + puzzle.size);
    if (!firstCol) surrounding.push(index - 1);
    if (!lastCol) surrounding.push(index + 1);
    if (!firstRow && !firstCol) surrounding.push(index - puzzle.size - 1);
    if (!firstRow && !lastCol) surrounding.push(index - puzzle.size + 1);
    if (!lastRow && !firstCol) surrounding.push(index + puzzle.size - 1);
    if (!lastRow && !lastCol) surrounding.push(index + puzzle.size + 1);

    const stars = [];
    for (let i = 0; i < surrounding.length; i++) {
        const idx = surrounding[i];
        if (idx < 0 || idx >= placements.length) {
            continue;
        }
        if (
            placements[idx] === CellState.Star ||
            placements[idx] === CellState.StarInvalid ||
            placements[idx] === CellState.StarHint
        ) {
            stars.push(idx);
        }
    }

    if (stars.length > 1) {
        for (let j = 0; j < stars.length; j++) {
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.StarInvalid;
        }
        return true;
    } else {
        return false;
    }
};

const checkRegion = (
    puzzle: Puzzle,
    placements: number[],
    index: number,
): boolean => {
    const grid = puzzle.grid;
    const group = grid[index];

    const region = [];
    for (let i = 0; i < grid.length; i++) {
        if (grid[i] === group) {
            region.push(i);
        }
    }

    const stars = [];
    for (let i = 0; i < region.length; i++) {
        if (
            placements[region[i]] === CellState.Star ||
            placements[region[i]] === CellState.StarInvalid ||
            placements[region[i]] === CellState.StarHint
        ) {
            stars.push(region[i]);
        }
    }

    if (stars.length > puzzle.fit) {
        for (let j = 0; j < stars.length; j++) {
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.StarInvalid;
        }
        return true;
    } else {
        for (let j = 0; j < stars.length; j++) {
            // We don't want to overwrite a star that was already confirmed invalid.
            if (
                stars[j] < index &&
                placements[stars[j]] === CellState.StarInvalid
            ) {
                continue;
            }
            if (placements[stars[j]] === CellState.StarHint) {
                continue;
            }
            placements[stars[j]] = CellState.Star;
        }
        return false;
    }
};
