<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { type Puzzle, CellState } from "./types";
    import { isSolved, validatePlacements } from "./util";
    import DotIcon from "./icons/Dot.svelte";
    import CrownIcon from "./icons/Crown.svelte";

    interface Props {
        state: number;
        index: number;
        readonly: boolean;
        pressing: boolean;
    }

    let { state, index, readonly, pressing = $bindable() }: Props = $props();

    let puzzle = getContext<Puzzle>("puzzle");
    let placements = getContext<Writable<Array<CellState>>>("placements");
    let snapshots = getContext<Writable<Array<Array<CellState>>>>("snapshots");

    const grid = puzzle.grid;
    const size = puzzle.size;
    const group = grid[index];

    const place = () => {
        if (readonly) return;

        placements.update((p: Array<CellState>) => {
            if (!isSolved(puzzle, p)) {
                switch (p[index]) {
                    case CellState.Empty:
                        p[index] = CellState.X;
                        break;
                    case CellState.X:
                        snapshots.update((s: Array<Array<CellState>>) => {
                            const copy = p.slice();
                            copy[index] = CellState.Empty;
                            s.push(copy);
                            return s;
                        });

                        p[index] = CellState.Star;
                        p = validatePlacements(puzzle, p);

                        break;
                    case CellState.Star:
                    case CellState.StarInvalid:
                        p[index] = CellState.Empty;
                        p = validatePlacements(puzzle, p);
                        break;
                }
            }
            return p;
        });
    };

    const placeMarker = () => {
        if (readonly) return;

        placements.update((p: Array<CellState>) => {
            switch (p[index]) {
                case CellState.Empty:
                    p[index] = CellState.X;
                    break;
            }
            return p;
        });
    };

    const startPress = (e: PointerEvent) => {
        pressing = true;
        place();
        e.target.releasePointerCapture(e.pointerId);
    };
    const endPress = () => {
        pressing = false;
    };

    const borders = (group: number, neighbor: number) => {
        return group !== grid[neighbor];
    };

    let border = "";
    if (borders(group, index - 1) || index % size === 0) border += " border-l";
    if (borders(group, index - size) || index < size) border += " border-t";
    if (borders(group, index + 1) || index % size === size - 1) border += " border-r";
    if (borders(group, index + size) || index > size ** 2 - size) border += " border-b";

    let corner = "";
    if (index == 0) corner = "corner-tl";
    if (index == size - 1) corner = "corner-tr";
    if (index == size * size - size) corner = "corner-bl";
    if (index == size * size - 1) corner = "corner-br";
</script>

<!-- svelte-ignore a11y_click_events_have_key_events -->
<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
    class="cell cell-group-{group} {corner}"
    onclick={(e) => e.preventDefault()}
    onpointerdown={startPress}
    onpointerup={endPress}
    onpointerover={() => {
        if (pressing) placeMarker();
    }}
>
    {#if state === 1}
        <div class="cell-content" style="width: 50%; opacity: 0.6;">
            <DotIcon fill="#030104" />
        </div>
    {:else if state === 2}
        <div class="cell-content" style="width: 65%;">
            <CrownIcon fill="#000000" />
        </div>
    {:else if state === 3}
        <div class="cell-content" style="width: 65%;">
            <CrownIcon fill="#dc2626" />
        </div>
    {:else if state === 4}
        <div class="cell-content" style="width: 65%;">
            <CrownIcon fill="#b45309" />
        </div>
    {/if}
    <div class="cell-border {border}"></div>
</div>
