import Dexie, { type EntityTable } from "dexie";
import type { Attempt, Puzzle } from "./types";

const db = new Dexie("twokings") as Dexie & {
    puzzles: EntityTable<Puzzle, "id">;
    attempts: EntityTable<Attempt, "id">;
};

db.version(1).stores({
    puzzles: "++id, &date",
    attempts: "++id, &puzzle_id, &session",
});

export { db };
