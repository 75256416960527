<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import type { Writable } from "svelte/store";
    import { CellState, type Puzzle } from "./types";
    import UndoIcon from "./icons/Undo.svelte";
    import RedoIcon from "./icons/Redo.svelte";
    import { validatePlacements } from "./util";

    let puzzle = getContext<Puzzle>("puzzle");
    let placements = getContext<Writable<Array<CellState>>>("placements");
    let snapshots = getContext<Writable<Array<Array<CellState>>>>("snapshots");

    let resetStack: boolean = $state(true);
    let redoStack: Array<Array<CellState>> = $state([]);

    let undoAvailable = $derived($snapshots.length > 0);
    let redoAvailable = $derived(redoStack.length > 0);

    onDestroy(
        placements.subscribe((_p) => {
            if (resetStack) {
                redoStack = [];
            }
            resetStack = true;
        }),
    );

    const applySnapshot = (
        placements: Array<CellState>,
        snapshot: Array<CellState>,
    ) => {
        return validatePlacements(
            puzzle,
            snapshot.map((cell: CellState, i: number) => {
                if (placements[i] === CellState.StarHint) {
                    return CellState.StarHint;
                } else {
                    return cell;
                }
            }),
        );
    };

    const undo = () => {
        placements.update((p) => {
            const previous = $snapshots.pop();
            if (previous !== undefined) {
                redoStack.push($state.snapshot(p));
                p = applySnapshot(p, previous);
            }
            resetStack = false;
            return p;
        });
    };

    const redo = () => {
        placements.update((p) => {
            const next = $state.snapshot(redoStack.pop());
            if (next !== undefined) {
                $snapshots.push($state.snapshot(p));
                p = applySnapshot(p, next);
            }
            resetStack = false;
            return p;
        });
    };
</script>

<div>
    <button class="icon" disabled={!undoAvailable} onclick={undo}>
        <UndoIcon />
    </button>
    <button class="icon" disabled={!redoAvailable} onclick={redo}>
        <RedoIcon />
    </button>
</div>
