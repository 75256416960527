<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import type { Writable } from "svelte/store";
    import { Routes } from "@lib/types";
    import { formatTime, PuzzleDate } from "@lib/util";
    import type { Attempt, Puzzle } from "@lib/types";
    import { db } from "@lib/db";
    import ResetTimer from "@components/ResetTimer.svelte";
    import Logo from "@components/Logo.svelte";
    import Summary from "@components/Summary.svelte";
    import ShareIcon from "@icons/Share.svelte";

    let router = getContext<Writable<Routes>>("router");
    let date = getContext<Writable<PuzzleDate>>("date");

    let puzzle: Puzzle | undefined = $state(undefined);
    let attempt: Attempt | undefined = $state(undefined);

    const unsubscribe = date.subscribe(async (newDate: PuzzleDate) => {
        try {
            puzzle = await db.puzzles.get({ date: newDate.iso });
            if (puzzle) {
                attempt = await db.attempts.get({ puzzle_id: puzzle.id });
            }
        } catch (e) {
            // noop
        }
    });

    const goToArchive = () => {
        router.set(Routes.Archive);
    };

    const goToPuzzle = () => {
        router.set(Routes.Puzzle);
    };

    const onShareClick = async (e: any) => {
        let message = "";
        if (puzzle && attempt?.solved) {
            message = `I solved Two Kings #${puzzle.id} in ${formatTime(attempt.timer.elapsed)}. 👑👑`;
        }

        if (navigator.share) {
            try {
                await navigator.share({
                    title: "Two Kings",
                    text: message,
                    url: "https://twokings.xyz",
                });
            } catch (e) {
                // noop
            }
        } else {
            navigator.clipboard.writeText(`${message}\nhttps://twokings.xyz`);
            e.target.textContent = "Copied!";
            setTimeout(() => {
                e.target.textContent = "Share your time";
            }, 2000);
        }
    };

    onDestroy(unsubscribe);
</script>

<ResetTimer />

<div class="container">
    <main>
        <header>
            <Logo />
            <h4>TWO KINGS</h4>
        </header>

        <section>
            <div>
                <h1 class="fade-in">
                    {$date.date.toLocaleDateString("en-us", {
                        weekday: "long",
                    })}
                </h1>
                <h4 class="fade-in">{$date.friendly}</h4>
            </div>

            {#if puzzle && attempt?.solved}
                <div>
                    <Summary {puzzle} {attempt} />

                    <button class="link fade-in" onclick={goToPuzzle}>
                        Admire solution →
                    </button>
                </div>

                <button class="button fade-in" onclick={onShareClick}>
                    {#if navigator.share}
                        <div class="button-icon">
                            <ShareIcon />
                        </div>
                    {/if}
                    Share your time
                </button>

                <button class="archive-cta fade-in" onclick={goToArchive}>
                    <div>Looking for more puzzles?</div>
                    <div><b>Explore the archive →</b></div>
                </button>
            {:else}
                <button class="button fade-in" onclick={goToPuzzle}>
                    {#if attempt}
                        Continue
                    {:else}
                        Start
                    {/if}
                </button>
                <button class="link fade-in" onclick={goToArchive}>
                    Archive →
                </button>
            {/if}
        </section>

        <footer></footer>
    </main>
</div>

<style>
    .archive-cta {
        border: 2px solid var(--black);
        border-right-width: 4px;
        border-bottom-width: 4px;
        border-radius: 8px;
        background-color: var(--cell-yellow);
        padding: 1rem;
        width: 100%;
        cursor: pointer;
    }

    .container {
        background-color: var(--accent-color);
        color: var(--black);
        animation: 0.25s fade ease;
    }

    button.link {
        color: var(--black);
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
    }

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        text-align: center;
        padding: 2rem 0;
    }

    footer {
        flex-grow: 1;
    }
</style>
