import { writable } from "svelte/store";
import { PuzzleDate } from "./util";

const loadSettings = () => {
    const stored = localStorage.getItem("settings");
    if (stored) {
        return JSON.parse(stored);
    } else {
        return {
            dark_mode: false,
            hide_timer: false,
            hide_tips: false,
        };
    }
};

export const settings = writable(loadSettings());

settings.subscribe((s) => {
    localStorage.setItem("settings", JSON.stringify(s));
});

export const puzzleDate = writable<PuzzleDate>(new PuzzleDate());

