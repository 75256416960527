<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import type { Timer } from "./types";
    import PlayIcon from "./icons/Play.svelte";
    import PauseIcon from "./icons/Pause.svelte";

    const timer = getContext<Writable<Timer>>("timer");

    const pause = () => {
        timer.update((t) => ({ ...t, paused: true }));
    };

    const resume = () => {
        timer.update((t) => ({ ...t, paused: false }));
    };
</script>

{#if $timer.paused}
    <button id="pause" class="icon" onclick={resume}>
        <PlayIcon />
    </button>
{:else}
    <button id="pause" class="icon" onclick={pause}>
        <PauseIcon />
    </button>
{/if}
